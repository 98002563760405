import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import { useSpring, animated } from 'react-spring';
import { breakpoints, colors, dimensions } from '../../../utils/styles';
const CollapseMenu = props => {
  const { open } = useSpring({ open: props.navbarState ? 0 : 1 });

  if (props.navbarState === true) {
    return (
      <CollapseWrapper
        style={{
          transform: open
            .interpolate({
              range: [0, 0.2, 0.3, 1],
              output: [0, -20, 0, -200]
            })
            .interpolate(openValue => `translate3d(0, ${openValue}px, 0`)
        }}
      >
        <NavLinks>
          <li>
            <Link to="/" onClick={props.handleNavbar}>
              Inicio
            </Link>
          </li>
          <li>
            {' '}
            <Link to="/productos" onClick={props.handleNavbar}>
              Productos
            </Link>
          </li>
        </NavLinks>
      </CollapseWrapper>
    );
  }
  return null;
};

export default CollapseMenu;

const CollapseWrapper = styled(animated.div)`
  @media (min-width: ${breakpoints.desktop}px) {
    display: none;
  }
  background: #fff;
  position: fixed;
  top: ${dimensions.headerHeight};
  left: 0;
  right: 0;
  z-index: 1;
`;

const NavLinks = styled.ul`
  list-style-type: none;
  padding: 2rem 1rem 2rem 2rem;

  & li {
    transition: all 300ms linear 0s;
  }
  & a {
    font-size: 1.4rem;
    line-height: 2;
    color: ${colors.brand};
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: ${colors.brandDark};
      border-bottom: 1px solid ${colors.brandDark};
    }
  }
`;
