import React from 'react';
import styled from '@emotion/styled';
import { useSpring, animated, config } from 'react-spring';
import { Link } from 'gatsby';

import Logo from '../Logo';
import BurgerMenu from './BurgerMenu';
import CollapseMenu from './CollapseMenu';
import {
  breakpoints,
  colors,
  dimensions,
  spacing
} from '../../../utils/styles';

const Navbar = props => {
  const barAnimation = useSpring({
    from: { transform: 'translate3d(0, -10rem, 0)' },
    transform: 'translate3d(0, 0, 0)'
  });

  const linkAnimation = useSpring({
    from: { transform: 'translate3d(0, 30px, 0)', opacity: 0 },
    to: { transform: 'translate3d(0, 0, 0)', opacity: 1 },
    delay: 800,
    config: config.wobbly
  });

  return (
    <>
      <NavBar style={barAnimation}>
        <FlexContainer>
          <Logo />
          <NavLinks style={linkAnimation}>
            <Link to="/">Inicio</Link>
            <Link to="/productos">Productos</Link>
          </NavLinks>
          <BurgerWrapper>
            <BurgerMenu
              navbarState={props.navbarState}
              handleNavbar={props.handleNavbar}
            />
          </BurgerWrapper>
        </FlexContainer>
      </NavBar>
      <CollapseMenu
        navbarState={props.navbarState}
        handleNavbar={props.handleNavbar}
      />
    </>
  );
};

export default Navbar;

const NavBar = styled(animated.nav)`
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 2;
  font-size: 1rem;
  position: fixed;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
`;

const FlexContainer = styled.div`
  max-width: 120rem;
  display: flex;
  margin: auto;
  padding: 0 2rem;
  justify-content: space-between;
  height: ${dimensions.headerHeight};
`;

const NavLinks = styled(animated.ul)`
  justify-self: end;
  list-style-type: none;
  margin: auto 0;
  margin-right: ${dimensions.headerHeight};

  & a {
    color: ${colors.brand};
    text-transform: uppercase;
    font-weight: 600;
    border-bottom: 1px solid transparent;
    margin: 0 1.5rem;
    transition: all 300ms linear 0s;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      color: ${colors.brandDark};
      border-bottom: 1px solid ${colors.brandDark};
    }
    @media (max-width: ${breakpoints.desktop}px) {
      display: none;
    }
  }
`;

const BurgerWrapper = styled.div`
  margin: auto 0;
  margin-right: ${dimensions.headerHeight};
  @media (min-width: ${breakpoints.desktop}px) {
    display: none;
  }
`;
