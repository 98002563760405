import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions } from '../../../utils/styles';
const Burgermenu = props => {
  return (
    <Wrapper onClick={props.handleNavbar}>
      <div className={props.navbarState ? 'open' : ''}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>
    </Wrapper>
  );
};

export default Burgermenu;

const Wrapper = styled.div`
  position: relative;
  /* padding-top: .7rem; */
  cursor: pointer;
  display: block;
  & span {
    background: ${colors.brand};
    display: block;
    position: relative;
    width: 25px;
    height: 5px;
    margin-bottom: 4px;
    transition: all ease-in-out 0.2s;
  }
  .open span:nth-child(2) {
    opacity: 0;
  }
  .open span:nth-child(3) {
    transform: rotate(45deg);
    top: -9px;
  }
  .open span:nth-child(1) {
    transform: rotate(-45deg);
    top: 9px;
  }
`;
